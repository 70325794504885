
import c from "./HomeDescription.module.css";

const HomeDescription = () => {
    


    return (
      <div className={c.containerDes}>
        <div className={c.description}>
          <h1>Anass Zeroual</h1>
          <h2>Software Engineer</h2>
          <h3>FullStack Web Developer</h3>
        </div>
      </div>
    );
}

export default HomeDescription;